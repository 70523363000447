import { F7ExclamationmarkCircleFill } from '@/assets/icons/comm/F7ExclamationmarkCircleFill';
import { useTranslation } from 'react-i18next';

/**
 * 显示更改支付密码操作的安全提示
 * 该函数没有参数。
 * @returns 返回一个包含安全提示信息的JSX元素。
 */
export function ChangePayPasswordTips() {
  const { t } = useTranslation();

  // 创建并返回一个包含警告图标和安全提示文本的div元素
  return (
    <div className="flex gap-2 p-4 w-full gap-y-10 bg-primary/30   rounded-md">
      <span>
        <F7ExclamationmarkCircleFill className=" text-warningColor"></F7ExclamationmarkCircleFill>
      </span>
      <div className=" font-bold text-sm ">
        {t(
          '您即将进行支付密码的修改操作，请确保您处于安全网络环境，并且仅在私人设备上进行此项操作，以保护您的账户安全。',
        )}
      </div>
    </div>
  );
}
