import BaseTopNav from '@/components/base/baseTopNav';
import ChangePayPasswordForm from './components/ChangePayPasswordForm';
import {ChangePayPasswordTips} from './components/ChangePayPasswordTips';
import {useTranslation} from 'react-i18next';

/**
 * 更新支付密码页面
 * */
export default () => {
  const { t } = useTranslation();

  return (
    <>
      <BaseTopNav title={t('修改支付密码')} />
      <div className="p-4">
        <ChangePayPasswordTips></ChangePayPasswordTips>
      </div>
      <div className="px-4">
        <ChangePayPasswordForm ></ChangePayPasswordForm>
      </div>
    </>
  );
};
